import { useReducer, useRef } from "react"
import { useHistory } from "react-router-dom"

const OPEN_SEARCH_BOX = 1
const CHANGE_QUERY = 2
const CHANGE_ICON_VISIBILITY = 3
const useSearchbar = () => {
    const history = useHistory()
    const initialState = {
        isOpen: false,
        query: '',
        iconVisibility: false
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case OPEN_SEARCH_BOX://value
                return {
                    ...state,
                    isOpen: action.value
                }
            case CHANGE_QUERY://value
                return {
                    ...state,
                    query: action.value
                }
            case CHANGE_ICON_VISIBILITY://value
                return {
                    ...state,
                    iconVisibility: action.value
                }

        }
        return state
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const { isOpen, query } = state
    const inputBoxFocusRef = useRef()
    const searchIconClick = () => {
        if (!isOpen) {
            inputBoxFocusRef.current.focus()
        } else {
            inputBoxFocusRef.current.blur()
        }
        if (isOpen) changeQuery('')
        dispatch({
            type: OPEN_SEARCH_BOX,
            value: !isOpen
        })
    }

    const changeQuery = (text) => {
        dispatch({
            type: CHANGE_QUERY,
            value: text
        })
    }

    const submit = () => {
        if (query != '') {
            history.push(`/questions/search/${query}`)
        }
    }

    return {
        state, searchIconClick, inputBoxFocusRef, changeQuery, submit
    }
}

export default useSearchbar