import style from './Content.module.css'
import useContent from './useContent';
import { Route } from "react-router-dom";
import { lazy } from 'react'

const AddQuestions = lazy(() => import('../AddQuestions/addQuestions'))
const Reports = lazy(() => import("../Reports/Reports"))
const BrowseTerms = lazy(() => import("../BrowseTerms/browseTerms"))
const Admins = lazy(() => import('../Admins/Admins'))
const User = lazy(() => import('../user/User'))
const Term = lazy(() => import('../BrowseTerms/term'))
const CouponsTypes = lazy(() => import('../Coupons/CouponsTypes'))
const CouponsType = lazy(() => import('../Coupons/CouponsType'))
const CouponSearch = lazy(() => import('../Coupons/Search'))
const Coupons = lazy(() => import("../Coupons/Coupons"))
const Admin = lazy(() => import("../Admin/Admin"))
const AddAdmin = lazy(() => import("../Admins/AddAdmin/AddAdmin"))
const SimilarQuestions = lazy(() => import("../Questions/Similar/SimilarQuestions"))
const QuestionsSearch = lazy(() => import('../Questions/search/Search'))

function Content() {

    const { isLoggedIn } = useContent();
    return <div>
        {
            isLoggedIn ?
                [<Route path={["/termId", "/termId/*"]} component={Term} />
                    , <Route path={["/addQuestions/*"]} component={AddQuestions} />
                    , <Route path={["/question/:id/similar"]} component={SimilarQuestions} />
                    , <Route path="/admins" exact component={Admins} />
                    , <Route path="/user/:id" component={User} />
                    , <Route path="/reports" component={Reports} />
                    , <Route path="/couponsTypes" component={CouponsTypes} />
                    , <Route path="/couponsType/*" component={CouponsType} />
                    , <Route path="/coupons" component={Coupons} />
                    , <Route path="/admin/:id" component={Admin} />
                    , <Route path="/coupon/search/*" component={CouponSearch} />
                    , <Route path="/questions/search/:query" component={QuestionsSearch} />
                    , <Route path="/admins/add" component={AddAdmin} />
                    , <Route path="/browseTerms" component={BrowseTerms} />]
                : <></>
        }
    </div>
}
export default Content