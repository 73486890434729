import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { DialogueContext, RTLContext } from '../App'
import useBrowseRootTerms from './useBrowseRootTerms'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

function BrowseRootTerms({ showCheckBoxex = false, admin }) {

    const dialogueContext = useContext(DialogueContext)
    const rtlContext = useContext(RTLContext)
    const controller = useBrowseRootTerms()
    const { terms, addTermFormVisibility, addTermTarget, newTerm, saveCheckedTermsButton, termAllParents } = controller.state
    const { showAddOpenForm, saveNewTerm, editNewTerm, getTermDataBrRootTermId, addAllowedTerm } = controller
    return <div style={{ direction: rtlContext ? "rtl" : "ltr" }}>

        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>{
            termAllParents != null ? termAllParents.map((term, index) => {
                return <><div className="margin" style={{ cursor: "pointer" }}
                    onClick={e => {
                        getTermDataBrRootTermId(term.parentTermId ?? term.rootTermId, term.rootTermId)
                    }}>{term.name}</div>
                    <FontAwesomeIcon className="color-primary" icon={faAngleLeft} />
                </>
            }) : <></>}</div>
        {terms.map((term, index) => {
            if (!showCheckBoxex) {
                return <NavLink to={`/browseTerms/rootTermId/${term.rootTermId}`}>
                    <div onClick={e => { dialogueContext.hideDialogue() }} className="text-black center margin padding circular-border" >
                        {term.rootTermName}</div>
                </NavLink>
            }
            else {
                return <div onClick={e => {
                    getTermDataBrRootTermId(term.id ?? term.rootTermId, term.rootTermId)
                }} className="text-black margin padding circular-border"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        cursor: "pointer"
                    }}>
                    <div>{term.rootTermName ?? term.name}</div>
                    {
                        !term.hasOwnProperty('rootTermName') ? <Button size="sm"
                            onClick={e => addAllowedTerm(term.id, admin.id)}>اختيار</Button> : <></>
                    }
                </div>
            }
        })}
    </div>

}

export default BrowseRootTerms