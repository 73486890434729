import React, { useContext,useEffect } from 'react'
import { Privilages } from './App'


function Privilage({privilageId,children}) {
    const privilage=useContext(Privilages)

    useEffect(() => {
    }, [privilage])

    return privilage.privilages.length>0?
                ((parseInt(privilage.admin.privilagesSelectionType)+parseInt(privilage.privilages.filter(p=>p.id==privilageId)[0].selected))==1?
                children
                :<></>):<></>
            
}

export default Privilage