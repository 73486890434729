import React, { useContext, useReducer } from 'react'
import { AppContext, DialogueContext, PageContext, SignInDATA } from '../App';
import sign from './SignUp.module.css'
import { AppId, rootUrl } from '../constants';
import Network from '../utilities/Network';

const FILL_FIELD = 1

function SignUp() {
    const SignInData = useContext(SignInDATA)
    const pageContext = useContext(PageContext)
    const appContext = useContext(AppContext)
    const dialogueContext = useContext(DialogueContext)

    const initialState = {
        fields: {
            username: '',
            password: ''
        }
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case FILL_FIELD://field value
                return {
                    ...state,
                    fields: {
                        ...state.fields,
                        [action.field]: action.value
                    }
                }
            default: return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    function handleSubmit(event) {
        event.preventDefault()
        new Network({
            url: "/adminRegister.php",
            method: "post",
            context: pageContext,
            dialogueContext: dialogueContext,
            params: {
                'username': state.fields.username,
                'password': state.fields.password
            },
            onConnectionSucceed: (data) => localStorage.setItem("admin", JSON.stringify(data.admin))
        }).invoke();

        // pageContext.showLoading()
        // const params = new URLSearchParams();
        // params.append('username', state.fields.username);
        // params.append('password', state.fields.password);
        // params.append('appId', AppId)
        // params.append('deviceInfo', 'admin');

        // axios.post(`${rootUrl}adminRegister.php`, params)
        //     .then(respons => {
        //         pageContext.showLoading(false)
        //         if (respons.data.hasOwnProperty("result")) {
        //             if (respons.data.result == 'failed') {
        //                 SignInData.dispatch({
        //                     type: SignInData.SHOW_MESSAGE,
        //                     value: respons.data.message
        //                 })
        //             }
        //             else {
        //                 localStorage.setItem("admin", JSON.stringify(respons.data.admin))
        //                 SignInData.dispatch({
        //                     type: SignInData.SHOW_MESSAGE,
        //                     value: ''
        //                 })
        //             }
        //         }
        //         else SignInData.dispatch({
        //             type: SignInData.SHOW_MESSAGE,
        //             value: respons.data
        //         })
        //     }
        //     ).catch(err => {
        //         pageContext.showLoading(false)
        //         alert(err)
        //     })
    }
    return (
        <div className={sign["login-page"]}>
            <div className={sign["form"]}>
                <form className={sign["login-form"]} onSubmit={handleSubmit}>
                    <input type="text" placeholder="username"
                        value={state.fields.username}
                        onChange={e =>
                            dispatch({
                                type: FILL_FIELD,
                                field: "username",
                                value: e.target.value
                            })
                        } />
                    <input type="password" placeholder="password"
                        value={state.fields.password}
                        onChange={e =>
                            dispatch({
                                type: FILL_FIELD,
                                field: "password",
                                value: e.target.value
                            })
                        } />
                    <button>إنشاء</button>
                    <p className={sign["message"]}>مسجل مسبقاً؟ <a href="#"
                        onClick={e =>
                            SignInData.dispatch({
                                type: 1,
                                visibility: true
                            })
                        }
                    >تسجيل الدخول</a></p>
                </form>

            </div>
        </div>
    )
}

export default SignUp
