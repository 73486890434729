import { useReducer } from "react"
const ADD_STARTING_COMPONENT = 1
const ADD_ENDING_COMPONENT = 2
const SET_STARTING_COMPONENT = 3
const SET_ENDING_COMPONENT = 4
const SET_CENTER_COMPONENT = 5
const CLEAR_SECONDARY_NAVBAR = 6
const CLEAR_STARTING_COMPONENTS = 7

const useSecondaryNavBar = () => {
    const initialState = {
        startingComponents: [],
        centerComponents: [],
        endingComponents: [],
    }

    const isEmpty = () => {
        return state.startingComponents.length + state.centerComponents.length + state.endingComponents.length == 0
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case ADD_STARTING_COMPONENT://component
                state.startingComponents.push(action.component)
                return {
                    ...state,
                    startingComponents: state.startingComponents
                }
            case ADD_ENDING_COMPONENT://component
                state.endingComponents.push(action.component)
                return {
                    ...state,
                    endingComponents: state.endingComponents,
                }
            case SET_STARTING_COMPONENT://component
                return {
                    ...state,
                    startingComponents: [].concat(action.component)
                }
            case SET_ENDING_COMPONENT://component
                return {
                    ...state,
                    endingComponents: [action.component]
                }
            case SET_CENTER_COMPONENT://component
                return {
                    ...state,
                    centerComponents: [action.component]
                }
            case CLEAR_SECONDARY_NAVBAR:
                return {
                    ...state,
                    startingComponents: [],
                    centerComponents: [],
                    endingComponents: []
                }
            case CLEAR_STARTING_COMPONENTS:
                return {
                    ...state,
                    startingComponents: []
                }
            default:
                return state
        }
    }

    const addStartingComponent = (component) => {
        dispatch({
            type: ADD_STARTING_COMPONENT,
            component: component
        })
    }

    const addEndingComponent = (component) => {
        dispatch({
            type: ADD_ENDING_COMPONENT,
            component: component,
        })
    }

    const setStartingComponent = (component) => {
        dispatch({
            type: SET_STARTING_COMPONENT,
            component: component
        })
    }

    const setEndingComponent = (component) => {
        dispatch({
            type: SET_ENDING_COMPONENT,
            component: component
        })
    }

    const setCenterComponent = (component) => {
        dispatch({
            type: SET_CENTER_COMPONENT,
            component: component
        })
    }

    const clearSecondaryNavBar = () => {
        dispatch({
            type: CLEAR_SECONDARY_NAVBAR,
        })
    }

    const clearStartingComponents = () => {
        dispatch({
            type: CLEAR_STARTING_COMPONENTS
        })
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    return {
        state: state,
        isEmpty: isEmpty,
        setStartingComponent: setStartingComponent,
        clearSecondaryNavBar: clearSecondaryNavBar,
        addStartingComponent: addStartingComponent,
        setCenterComponent: setCenterComponent,
        addEndingComponent: addEndingComponent,
        setEndingComponent: setEndingComponent,
        clearStartingComponents: clearStartingComponents
    }
}
export default useSecondaryNavBar