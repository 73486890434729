import axios from "axios";
import { Button } from "react-bootstrap";
import { AppId, rootUrl } from "../constants";

class Network {

    onPreConnect;
    onPostConnect;
    onConnectionFailed;
    onConnectionSucceed;
    onMessageReceived;

    url;
    params;
    method;
    context;
    dialogueContext;

    constructor({ url, method, context, dialogueContext, onPreConnect = () => {
        this.context.showLoading();
        this.context.hideNoticeMessage()
    }, onPostConnect = () => {
        this.context.showLoading(false);
    }, onConnectionSucceed = (data) => { }, onConnectionFailed = () => {
        this.dialogueContext.editDialogue({
            title: "لا يتوفر اتصال بالشبكة",
            content: "لا يتوفر اتصال بالشبكة، تأكد من إعدادات الاتصال الخاصة بك ثم حاول مجدداً",
            children: [
                <Button onClick={e => {
                    this.dialogueContext.hideDialogue()
                    Network.instances[0].invoke()
                }}>المحاولة مجدداً</Button>
            ]
        })
    }, onMessageReceived = (message) => {
        console.log(message)
        this.context.showNoticeMessage(message)
    }, params = {} }) {
        this.onPreConnect = onPreConnect
        this.onPostConnect = onPostConnect
        this.onConnectionFailed = onConnectionFailed
        this.onConnectionSucceed = onConnectionSucceed
        this.onMessageReceived = onMessageReceived

        var paramsCopy = params;
        params = new URLSearchParams();

        params.append('deviceInfo', 'admin');
        if (localStorage.getItem("admin") != null) params.append('adminId', JSON.parse(localStorage.getItem("admin")).id + '');
        params.append('appId', AppId)
        for (let key of Object.keys(paramsCopy)) {
            let value = paramsCopy[key]
            params.append(key, value)
        }
        this.url = url;
        this.params = params;
        this.method = method;
        this.context = context;
        this.dialogueContext = dialogueContext;
        Network.instances.push(this)
    }

    static instances = []



    invoke = () => {
        if (Network.instances[0] != this) return;
        this.onPreConnect()
        axios({
            method: this.method,
            url: this.url,
            baseURL: rootUrl,
            data: this.params
        }).then(response => {
            console.log(response)
            if (response.data.hasOwnProperty("result")) {
                if (response.data.result == 'failed') {
                    this.onMessageReceived(response.data.message)
                }
                else {
                    if (response.data.hasOwnProperty("successMessage")) {
                        this.context.showToast({
                            type: "success",
                            "title": response.data.successMessage.title,
                            "description": response.data.successMessage.description
                        })
                    }
                    this.onConnectionSucceed(response.data)
                    Network.instances.shift()
                    if (Network.instances.length > 0) Network.instances[0].invoke()
                }
            }
            else {
                this.onMessageReceived(JSON.stringify(response.data))
            }
        }).catch(error => {
            console.log(error)
            try {
                if (error.toJSON().message === 'Network Error') {
                    this.onConnectionFailed()
                }
                else this.onMessageReceived(error.toJSON())
            }
            catch (e) {
                this.onMessageReceived(error)
                console.log(e)
            }
        }).finally(() => {
            this.onPostConnect()
        })
    }
}

export default Network