import style from './Searchbar.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import useSearchbar from './useSearchbar'

const Searchbar = () => {
    const controller = useSearchbar()
    const { isOpen, query } = controller.state

    return <form onSubmit={e => {
        e.preventDefault()
        controller.submit()
    }}><div
        className={style['search-box']}
        onMouseEnter={e =>
            controller.searchIconClick()
        }
        onMouseLeave={e =>
            controller.searchIconClick()
        }
    >
            <div
                className={style["search-btn"]}
                onClick={e =>
                    controller.submit()
                }
            >
                <FontAwesomeIcon icon={faSearch} />
            </div>

            <input className={style["search-text"]}
                type="text"
                placeholder="البحث عن أسئلة"
                value={query}
                ref={controller.inputBoxFocusRef}
                onChange={e => {
                    controller.changeQuery(e.target.value)
                }} />

        </div></form>
}
export default Searchbar