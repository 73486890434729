import React, { useContext } from 'react'
import { SecondaryNavBarContext } from '../../App'
import style from './SecondaryNavBar.module.css'

function SecondaryNavBar() {
    const secondaryNavBarContext = useContext(SecondaryNavBarContext)
    const state = secondaryNavBarContext.state

    return secondaryNavBarContext.isEmpty() ? <></> : <div className={style.secondary_navbar}>
        <div className={style.group}>
            {
                state.startingComponents.map((component) => {
                    return component
                })
            }
        </div>
        <div>{state.centerComponents}</div>
        <div className={style.group} style={{ flexDirection: "row-reverse" }}>{
            state.endingComponents.map((component) => {
                return component
            })
        }</div>
    </div>
}
export default SecondaryNavBar