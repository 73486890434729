import React, { useReducer } from 'react'
import AppConfig from './config/appConfig';

const CONFIGURE_SIGN_IN_VISIBILITY = 1
const SHOW_LOADING = 2
const SHOW_MESSAGE = 3
const LOAD_ADMIN_PRIVILAGES = 4
const SHOW_ROOT_TERMS = 5
const SHOW_ROOT_TERMS_FROM_TAGS = 6
const REFRESH = 7
const MODIFY_NOTIFICATION = 8
const EDIT_DIALOGUE = 9
const ADD_DIALOGUE_CHILD = 10
const INVOKE_SIDE_BAR = 11
const SIDE_BAR_ACTIVE_ITEM = 12
const HIDE_TOAST = 13
const SHOW_TOAST = 14
const CLEAR_DIALOGUE_CHILDREN = 15

export default function useApp() {
    const uuid = () => {
        var dt = new Date().getTime();
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case CONFIGURE_SIGN_IN_VISIBILITY:
                return {
                    ...state,
                    signInVisibile: action.visibility
                }
            case SHOW_LOADING: {//value
                return {
                    ...state,
                    loading: action.value
                }
            }
            case SHOW_MESSAGE: {//value
                return {
                    ...state,
                    message: action.value
                }
            }
            case LOAD_ADMIN_PRIVILAGES://privilages admin
                return {
                    ...state,
                    privilages: action.privilages,
                    admin: action.admin,
                }
            case SHOW_ROOT_TERMS: {//value
                return {
                    ...state,
                    showRootTerms: action.value
                }
            }
            case SHOW_ROOT_TERMS_FROM_TAGS://value
                return {
                    ...state,
                    showRootTermsFromTags: action.value
                }
            case REFRESH:
                return {
                    ...state
                }
            case MODIFY_NOTIFICATION://param value
                return {
                    ...state,
                    notification: {
                        ...state.notification,
                        [action.param]: action.value
                    }
                }
            case EDIT_DIALOGUE://title content visible children
                return {
                    ...state,
                    confirmDialogueTitle: action.title != null ? action.title : state.confirmDialogueTitle,
                    confirmDialogueContent: action.content != null ? action.content : state.confirmDialogueContent,
                    confirmDialogueVisibility: action.visible != null ? action.visible : state.confirmDialogueVisibility,
                    confirmDialogueButtons: action.children != null ? action.children : state.confirmDialogueButtons,
                }
            case ADD_DIALOGUE_CHILD://child
                return {
                    ...state,
                    confirmDialogueButtons: state.confirmDialogueButtons.concat(action.child)
                }
            case CLEAR_DIALOGUE_CHILDREN:
                return {
                    ...state,
                    confirmDialogueButtons: []
                }
            case INVOKE_SIDE_BAR:
                return {
                    ...state,
                    sideBarVisible: !state.sideBarVisible
                }
            case SIDE_BAR_ACTIVE_ITEM:
                return {//item
                    ...state,
                    activeSideBarItem: action.item
                }
            case HIDE_TOAST:
                return {//id
                    ...state,
                    toastList: state.toastList.filter((toast) => toast.id != action.id),
                }
            case SHOW_TOAST:
                return {//title description typee
                    ...state,
                    toastList: state.toastList.concat({
                        title: action.title,
                        description: action.description,
                        type: action.typee,
                        id: uuid(),
                    })
                }
            default:
                return state
        }
    }

    const initialState = {
        signInVisibile: true,
        loading: false,
        message: '',
        admin: {},
        privilages: [],
        showRootTerms: false,
        showRootTermsFromTags: false,
        notification: {
            title: '',
            body: ''
        },
        confirmDialogueVisibility: false,
        confirmDialogueTitle: '',
        confirmDialogueContent: '',
        confirmDialogueButtons: [],
        appConfig: new AppConfig(),
        sideBarVisible: false,
        rtl: true,
        activeSideBarItem: '',
        toastList: [],
    }

    const [state, dispatch] = useReducer(reducer, initialState)
    const { signInVisibile, confirmDialogueButtons, confirmDialogueContent, confirmDialogueVisibility, confirmDialogueTitle } = state

    function isLoggedIn() {
        return localStorage.getItem("admin") != null
    }

    const showLoading = (loading = true) => {
        dispatch({
            type: SHOW_LOADING,
            value: loading
        })
    }

    const showNoticeMessage = (message) => {
        dispatch({
            type: SHOW_MESSAGE,
            value: message
        })
    }

    const hideNoticeMessage = () => showNoticeMessage('')

    const editDialogue = ({ title, content, visible = true, children }) => {
        dispatch({
            type: EDIT_DIALOGUE,
            title: title,
            content: content,
            visible: visible,
            children: children
        })
    }

    const addDialogueChild = ({ child }) => {
        dispatch({
            type: ADD_DIALOGUE_CHILD,
            child: child
        })
    }

    const hideDialogue = () => {
        editDialogue({
            title: '',
            content: '',
            visible: false,
            children: []
        })
    }

    const invokeSideBar = () => {
        dispatch({
            type: INVOKE_SIDE_BAR,
        })
    }

    const setSideBarActiveItem = (activeItem) => {
        dispatch({
            type: SIDE_BAR_ACTIVE_ITEM,
            item: activeItem
        })
    }

    const customStyle = {}
    if (state.rtl) {
        if (state.sideBarVisible) {
            customStyle.width = "calc(100%-225px)"
            customStyle.marginRight = 225;
        }
        else {
            customStyle.width = "100%"
            customStyle.marginRight = 0
        }
    }
    else {
        if (state.sideBarVisible) {
            customStyle.width = "calc(100%-225px)"
            customStyle.marginLeft = 225
        }
        else {
            customStyle.width = "100%"
            customStyle.marginLeft = 0
        }
    }
    const showToast = ({ type, title, description }) => {
        dispatch({
            type: SHOW_TOAST,
            title: title,
            description: description,
            typee: type
        })
    }

    const hideToast = (id) => {
        dispatch({
            type: HIDE_TOAST,
            id: id
        })
    }

    const setPrivilages = (privilages, admin) => {
        dispatch({
            type: LOAD_ADMIN_PRIVILAGES,
            privilages: privilages,
            admin: admin,
        })
    }

    const clearDialogueChildren = () => {
        dispatch({ type: CLEAR_DIALOGUE_CHILDREN })
    }
    return {
        state,
        confirmDialogueButtons,
        confirmDialogueContent,
        confirmDialogueTitle,
        confirmDialogueVisibility,
        signInVisibile,
        isLoggedIn,
        dispatch,
        hideToast,
        showToast,
        hideNoticeMessage,
        showNoticeMessage,
        showLoading,
        customStyle,
        setSideBarActiveItem,
        invokeSideBar,
        setPrivilages,
        clearDialogueChildren,
        addDialogueChild,
        editDialogue,
        hideDialogue
    }
}
