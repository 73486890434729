import { useContext, useEffect, useReducer } from 'react'
import { AppContext, DialogueContext, PageContext, Privilages } from '../App';
import Network from '../utilities/Network';
import AppConfig from '../config/appConfig';

const CONFIGE_APP = 10
const useContent = () => {
    const dialogueContext = useContext(DialogueContext)
    const pageContext = useContext(PageContext)
    const privilageContext = useContext(Privilages)
    const appContext = useContext(AppContext)

    function isLoggedIn() {
        return localStorage.getItem("admin") != null
    }

    const initialState = {
        admin: {},
        privilages: [],
        appConfig: new AppConfig(),
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case CONFIGE_APP://app
                return {
                    ...state,
                    appConfig: appContext.value.fromMap(action.app)
                }
            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const loadAdminPrivilages = () => {
        new Network({
            url: '/getAdminPrivilages.php',
            method: 'post',
            context: pageContext,
            dialogueContext: dialogueContext,
            onConnectionSucceed: (data) => {
                dispatch({
                    type: CONFIGE_APP,
                    app: data.app
                })
                privilageContext.setPrivilages(data.adminsPrivilages, data.admin)
            }
        }).invoke()
    }

    useEffect(() => {

        if (isLoggedIn()) loadAdminPrivilages()
    }, [isLoggedIn()])

    return {
        isLoggedIn: isLoggedIn()
    }
}
export default useContent