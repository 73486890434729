import { useReducer, useEffect, useContext } from "react";
import { PageContext, DialogueContext, SecondaryNavBarContext } from '../App'
import Network from "../utilities/Network";

const FETCH_SUCCESS = 2
const CONFIGURE_TERM_OPEN = 3
const SHOW_ADD_TERM_FORM = 4
const EDIT_NEW_TERM = 5
const SAVE_NEW_TERM = 6
const TERM_FETCH_SUCCESS = 7

const useBrowseRootTerms = () => {
    const pageContext = useContext(PageContext)
    const dialogueContext = useContext(DialogueContext)
    const secondaryNavBarContext = useContext(SecondaryNavBarContext)

    const getData = () => {
        new Network({
            url: "/getAllRootTermsIds.php",
            method: "post",
            context: pageContext,
            dialogueContext: dialogueContext,
            params: {
                'adminId': JSON.parse(localStorage.getItem("admin")).id + '',
            },
            onConnectionSucceed: (data) => {
                dispatch({
                    type: FETCH_SUCCESS,
                    terms: data.terms
                })
            }
        }).invoke();
    }

    useEffect(() => {
        getData()
    }, [])

    const getTermDataBrRootTermId = (termId, rootTermId) => {
        new Network({
            url: "/getTermDataByRootTerm.php",
            method: "post",
            context: pageContext,
            dialogueContext: dialogueContext,
            params: {
                termId: termId,
                rootTermId: rootTermId
            },
            onConnectionSucceed: (data) => {
                dispatch({
                    type: TERM_FETCH_SUCCESS,
                    terms: data.terms,
                    termAllParents: data.termAllParents,
                })
            }
        }).invoke();
    }

    const initialState = {
        terms: [],
        addTermFormVisibility: false,
        addTermTarget: null,
        newTerm: {},
        saveCheckedTermsButton: false,
        openedTerms: []
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case FETCH_SUCCESS:
                return {
                    ...state,
                    terms: action.terms
                }
            case CONFIGURE_TERM_OPEN://open index
                var terms = state.data.terms
                var term = terms[action.index]
                terms[action.index].open = action.open
                return {
                    ...state,
                    data: {
                        ...state.data,
                        terms: terms
                    },
                    openedTerms: action.open == true ? state.openedTerms.concat(term)
                        : state.openedTerms.filter(t => {
                            if (Object.keys(term).indexOf("id") > -1) return t.id != term.id
                            return t.rootTermId != term.rootTermId
                        })
                }
            case SHOW_ADD_TERM_FORM://visibility target
                return {
                    ...state,
                    addTermFormVisibility: action.visibility,
                    addTermTarget: action.target
                }
            case EDIT_NEW_TERM://param value
                return {
                    ...state,
                    newTerm: {
                        ...state.newTerm,
                        [action.param]: action.value
                    }
                }
            case SAVE_NEW_TERM:
                return {
                    ...state,
                    data: {
                        ...state.data,
                        terms: state.data.terms.concat(state.newTerm)
                    },
                    newTerm: {}
                }
            case TERM_FETCH_SUCCESS://terms termAllParents freeTermsRootTermId termsTypes termsTags
                return {
                    ...state,
                    terms: action.terms,
                    termAllParents: action.termAllParents,
                }
            default: return state

        }
    }

    function saveNewTerm() {
        dispatch({
            type: SAVE_NEW_TERM
        })
    }

    function editNewTerm(term, e) {
        dispatch({
            type: EDIT_NEW_TERM,
            param: term.param,
            value: e.target.value
        })
    }

    function showAddOpenForm(visible, target) {
        dispatch({
            type: SHOW_ADD_TERM_FORM,
            visibility: visible,
            target: target
        })
    }

    const addAllowedTerm = (termId, adminId) => {
        new Network({
            url: "/addAllowedTerm.php",
            method: "post",
            context: pageContext,
            dialogueContext: dialogueContext,
            params: {
                termId: termId,
                adminId: adminId
            },
            onConnectionSucceed: (data) => {
                dialogueContext.hideDialogue()
                window.location.reload()
            }
        }).invoke();
    }

    const [state, dispatch] = useReducer(reducer, initialState)
    const { terms, addTermFormVisibility, addTermTarget, newTerm, saveCheckedTermsButton } = state
    return {
        state: state,
        showAddOpenForm: showAddOpenForm,
        editNewTerm: editNewTerm,
        saveNewTerm: saveNewTerm,
        getTermDataBrRootTermId: getTermDataBrRootTermId,
        addAllowedTerm: addAllowedTerm
    }
}
export default useBrowseRootTerms